import Vue from 'vue'
import Router from 'vue-router'
import tlLayout from '@/components/layout/tl-layout.vue'
import localeGuard from '@/services/i18n/locale.guard'
import outdatedGuard from '@/components/outdated/outdated.guard'
import i18nStore from '@/services/i18n/i18n.store'
import goTo from 'vuetify/es5/services/goto'
import { combineRoutes } from '@/services/utils'
import multiguard from 'vue-router-multiguard'

const locales = i18nStore.state.localeDefs.map(l => l.code)

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: `/:locale(${locales.join('|')})?`,
      name: 'layout',
      component: tlLayout,
      beforeEnter: multiguard([localeGuard, outdatedGuard]),
      redirect: { name: 'home' },
      children: [
        {
          path: 'home',
          name: 'home',
          component: () => import(/* webpackChunkName: 'tl-home' */ '@/components/home/tl-home.vue'),
        },
        {
          path: 'gym-owners',
          name: 'gymOwners',
          component: () => import(/* webpackChunkName: 'tl-gym-owners' */ '@/components/gym-owners/tl-gym-owners.vue'),
        },
        {
          path: 'about',
          name: 'about',
          component: () => import(/* webpackChunkName: 'tl-about' */ '@/components/about/tl-about.vue'),
        },
        {
          path: '*',
          redirect: { name: 'home' },
        },
      ],
    },
    {
      path: '/outdated',
      name: 'outdatedApp',
      component: () => import(/* webpackChunkName: 'tl-outdated' */ '@/components/outdated/tl-outdated.vue'),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo, { container: 'main .tl-main__scroll-container' })
  },
})

// Smarter routing functions that merge the passed params
// into the current route's name, hash, params and query:
router.pushSmart = newRoute => {
  router.push(combineRoutes(router.currentRoute, newRoute))
}
router.replaceSmart = newRoute => {
  router.replace(combineRoutes(router.currentRoute, newRoute))
}

export default router
