<template>
  <v-main class="tl-main">
    <div class="tl-main__scroll-container">
      <slot />
    </div>
  </v-main>
</template>

<script>
export default {
  mounted: function () {
    let elHtml = document.getElementsByTagName('html')[0]
    elHtml.style.overflowY = 'hidden'
  },
  destroyed: function () {
    let elHtml = document.getElementsByTagName('html')[0]
    elHtml.style.overflowY = null
  },
}
</script>

<style lang="sass">
.tl-main
  height: 100vh
  &__scroll-container
    height: 100%
    overflow: auto
    -webkit-overflow-scrolling: touch
</style>
