<template>
  <v-app>
    <v-navigation-drawer v-model="sidenav" class="hidden-md-and-up" disable-resize-watcher fixed app>
      <tl-nav-toolbar />
      <v-list>
        <v-list-item
          v-for="(item, i) in menuItems"
          :key="i"
          :to="item.to"
          :href="item.href"
          :target="item.target"
          exact
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="$t(item.title)" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar fixed app color="white" class="v-toolbar--site">
      <v-app-bar-nav-icon class="hidden-md-and-up" @click="sidenav = !sidenav" />
      <v-toolbar-title class="hidden-md-and-up">
        {{ $t(currentMenuItem.title) }}
      </v-toolbar-title>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          v-for="(item, i) in menuItems"
          :key="i"
          :to="item.to"
          :href="item.href"
          :target="item.target"
          exact
          class="v-toolbar--site__btn"
          text
        >
          {{ $t(item.title) }}
        </v-btn>
      </v-toolbar-items>
      <v-spacer />
      <tl-locale-selector :compact="$vuetify.breakpoint.smAndDown" :single-line="true" />
    </v-app-bar>

    <tl-main class="tl-site-content">
      <router-view />
    </tl-main>
  </v-app>
</template>

<script>
import tlNavToolbar from './nav-toolbar/tl-nav-toolbar.vue'
import tlLocaleSelector from '@/components/shared/tl-locale-selector'
import localeGuard from '@/services/i18n/locale.guard'
import tlMain from '@/components/shared/tl-main'
import { tliAccountTie, tliHome, tliLightbulbOn, tliMapMarker, tliOpenInApp } from '@/assets/icons'

export default {
  components: {
    tlNavToolbar,
    tlLocaleSelector,
    tlMain,
  },
  data: () => ({
    sidenav: false,
  }),
  computed: {
    menuItems() {
      return [
        { title: 'layout.home', to: { name: 'home' }, icon: tliHome },
        { title: 'layout.gymOwners', to: { name: 'gymOwners' }, icon: tliAccountTie },
        { title: 'layout.about', to: { name: 'about' }, icon: tliLightbulbOn },
        { title: 'layout.contact', to: { name: 'about', hash: '#contact' }, icon: tliMapMarker },
        { title: 'layout.app', href: this.$store.getters.appLink, target: '_blank', icon: tliOpenInApp },
      ].map(item => {
        if (item.to) {
          item.to.params = { ...this.$route.params }
        }
        return item
      })
    },
    currentMenuItem() {
      return (
        this.menuItems.find(
          item => item.to && item.to.name == this.$route.name && (!item.to.hash || item.to.hash == this.$route.hash)
        ) || this.menuItems[0]
      )
    },
  },
  beforeRouteUpdate: localeGuard,
}
</script>

<style lang="sass">
.tl-site-content
  h1
    text-transform: uppercase
    font-size: 3em
    @include media-breakpoint('xs-only')
      font-size: 2.4em
  h2
    text-transform: uppercase
    font-size: 2em
    @include media-breakpoint('xs-only')
      font-size: 1.8em
  p
    line-height: 1.5em
    font-family: 'Raleway'

  .tl-subhead
    @include tl-merriweather
    color: var(--v-grey-lighten1)
    font-size: 1.2em
  .tl-separator-simple
    display: inline-block
    width: 50px
    height: 4px
    background-color: var(--v-primary-base)

.v-toolbar--site
  &__btn
    text-transform: inherit
    font-weight: inherit
    font-size: 18px !important
    &.v-btn
      color: black
    &.v-btn--active,
    &.v-btn:hover,
    &.v-btn:focus
      color: var(--v-primary-base)
      &:before
        background-color: white
</style>
