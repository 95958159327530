import Vue from 'vue'
import VueI18n from 'vue-i18n'
import i18nStore from '@/services/i18n/i18n.store'

let numberFormats = i18nStore.state.localeDefs.reduce((formats, l) => {
  formats[l.code] = {
    currency: {
      style: 'currency',
      currency: l.currency,
    },
  }
  return formats
}, {})

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'en-us',
  fallbackLocale: 'en-us',
  numberFormats,
  silentFallbackWarn: true,
  messages: {},
})
