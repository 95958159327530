export const tlClickOutside = {
  bind(el, binding) {
    el.tlClickOutside = event => {
      if (event.parentElement === el) return
      binding.value(event)
    }
    el.addEventListener('click', e => (e.parentElement = el))
    document.body.addEventListener('click', el.tlClickOutside)
  },
  unbind(el) {
    el.removeEventListener('click', e => (e.parentElement = el))
    document.body.removeEventListener('click', el.tlClickOutside)
  },
}
