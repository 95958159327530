import Vue from 'vue'

// Polyfills
import 'intersection-observer'

// Plugins
import './plugins/moment'
import './plugins/vue-meta'

// Globals
import './services/vue-globals'

// App
import app from './app.vue'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(app),
}).$mount('#app')
