export default [
  { code: 'en-us', price: 89.95, currency: 'USD', name: 'English - United States' },
  { code: 'en-gb', fallback: 'en-us', price: 64.95, currency: 'GBP', name: 'English - United Kingdom' },
  { code: 'en-au', fallback: 'en-us', price: 119.95, currency: 'AUD', name: 'English - Australia' },
  { code: 'es', price: 74.95, currency: 'EUR', name: 'Español' },
  { code: 'nl', price: 74.95, currency: 'EUR', name: 'Nederlands' },
  { code: 'de', price: 74.95, currency: 'EUR', name: 'Deutsch' },
  { code: 'de-ch', price: 84.95, currency: 'CHF', name: 'Deutsch - Schweiz', fallback: 'de' },
  { code: 'fr', price: 74.95, currency: 'EUR', name: 'Français' },
  { code: 'fr-ch', price: 84.95, currency: 'CHF', name: 'Français - Suisse', fallback: 'fr' },
]
