import Vue from 'vue'
import { camelToKebab } from '@/services/utils'

// https://webpack.js.org/guides/dependency-management/
function importAll(r) {
  return r.keys().reduce((imported, key) => Object.assign(imported, r(key)), {})
}

const filters = importAll(require.context('./filters', false, /\.filter\.js$/i))
const directives = importAll(require.context('./directives', false, /\.directive\.js$/i))

Object.keys(filters).forEach(name => {
  Vue.filter(name, filters[name])
})

Object.keys(directives).forEach(name => {
  Vue.directive(camelToKebab(name), directives[name])
})
