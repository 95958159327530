import Vue from 'vue'
import Vuex from 'vuex'
import i18nStore from '@/services/i18n/i18n.store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    i18n: i18nStore,
  },
  state: {},
  getters: {
    appLink(state) {
      return `https://app.toplogger.nu/${state.i18n.locale}`
    },
  },
  mutations: {},
  actions: {},
})
