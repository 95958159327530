// Capitalizes first letter.
export function capitalize(str) {
  if (!str) return ''
  str = str.toString()
  return str.charAt(0).toUpperCase() + str.slice(1)
}

// Convert CamelCase to kebap-case.
export function camelToKebab(str) {
  if (!str) return ''
  str = str.toString()
  return str.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase()
}

// Convert kebap-case or lower_kebap_case to CamelCase.
export function kebapToCamel(str) {
  if (!str) return ''
  str = str.toString()
  return str.replace(/[-_]([a-z])/g, g => g[1].toUpperCase())
}

// Pluralize unless count is 1
export function pluralize(word, count) {
  if (word.endsWith('s')) word = word.slice(0, -1)
  return count != 1 ? `${word}s` : word
}

// Singularize unless count > 1
export function singularize(word, count) {
  if (word.endsWith('s')) word = word.slice(0, -1)
  return count > 1 ? `${word}s` : word
}

// Math.round, but with precision. Defaults to 0 decimals.
export function round(value, precision) {
  let multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}

// Is like new Date(), but then rounded to seconds. Prevents dirty orm objects as the server returns in seconds.
export function nowRoundedToSec() {
  return new Date(1000 * Math.floor(Date.now() / 1000))
}

// Math.min for arrays, with customizable fallback for empty arrays
export function arrayMin(arr, fallback = Infinity) {
  if (!arr.length) return fallback
  return Math.min.apply(null, arr)
}

// Math.max for arrays, with customizable fallback for empty arrays
export function arrayMax(arr, fallback = -Infinity) {
  if (!arr.length) return fallback
  return Math.max.apply(null, arr)
}

// Creates a route object copy with attributes of 'update' merged
export function combineRoutes(original, update) {
  return {
    name: update.name || original.name,
    hash: update.hash || original.hash,
    query: { ...original.query, ...update.query },
    params: { ...original.params, ...update.params },
    replace: update.replace || original.replace,
  }
}
