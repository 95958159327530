import Vue from 'vue'
import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib'
import VuetifyToast from 'vuetify-toast-snackbar-ng'

import colors from 'vuetify/lib/util/colors'
import { tliChevronDown, tliMenu, tliMenuDown, tliPencil } from '@/assets/icons'

// Map internal Vuetify icon names to actual TopLogger icon names.
const tlIcons = {
  complete: 'tl-check',
  cancel: 'tl-cancel',
  close: 'tl-close',
  delete: 'tl-cancel',
  clear: 'tl-clear',
  success: 'tl-check-circle',
  info: 'tl-info',
  warning: 'tl-priority-high',
  error: 'tl-warning',
  prev: 'tl-chevron-left',
  next: 'tl-chevron-right',
  checkboxOn: 'tl-check-box',
  checkboxOff: 'tl-check-box-outline-blank',
  checkboxIndeterminate: 'tl-indeterminate-check-box',
  delimiter: 'tl-fiber-manual-record',
  sort: 'tl-arrow-upward',
  expand: tliChevronDown,
  menu: tliMenu,
  subgroup: tliMenuDown,
  dropdown: tliMenuDown,
  radioOn: 'tl-radio-button-checked',
  radioOff: 'tl-radio-button-unchecked',
  edit: tliPencil,
}

let theme = {
  primary: '#FF0094',
  accent: '#0eff00',
  info: '#0D47A1',
  warning: '#ffb300',
  error: '#B71C1C',
  success: '#2E7D32',
  grey: colors.grey,
}

const opts = {
  icons: {
    values: tlIcons,
  },
  theme: {
    dark: false,
    themes: {
      light: theme,
      dark: theme,
    },
    options: {
      customProperties: true,
    },
  },
}

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
  },
})
Vue.use(VuetifyToast)
export default new Vuetify(opts)
